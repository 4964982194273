@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap');

*,
html,
body {
  font-family: "Outfit", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-page * {
  font-family: 'Inter', sans-serif !important;
  box-sizing: border-box;
}

.heading {
  font-family: 'DM Sans', sans-serif !important;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c1c1c1;
}

.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.no-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #ffffff inset !important;
  -webkit-text-fill-color: #858585;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #ffffff inset !important;
  -webkit-text-fill-color: #858585;
}

.input--number:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  -webkit-text-fill-color: #858585;
}

button:focus,
a:focus,
input:focus,
textarea:focus,
button:focus-within,
a:focus-within,
input:focus-within,
textarea:focus-within,
button:focus-visible,
a:focus-visible,
input:focus-visible,
textarea:focus-visible {
  outline: none;
}

.sample-table {
  display: table;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.parameter-table {
  display: table;
  border-collapse: separate;
  border-spacing: 0 0px;
}

.parameter-table thead tr th {
  border-bottom: 1px solid #CBD5E1;
}

.parameter-table tbody tr td {
  border-top: 1px solid #fff;
  border-bottom: 1px solid transparent;
}

.parameter-table tr:hover td {
  border-top: 1px solid rgba(141, 159, 255, 0.40);
  border-bottom: 1px solid rgba(141, 159, 255, 0.40);
}

.parameter-table .parameter-active td {
  border-top: 1px solid rgba(141, 159, 255, 0.40) !important;
  border-bottom: 1px solid rgba(141, 159, 255, 0.40) !important;
}

.parameter-result .parameter-active td {
  border-top: 1px solid #8D9FFF !important;
  border-bottom: 1px solid #8D9FFF !important;
  background: #F7FAFF !important;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08) !important;
}

.prev-report {
  background: linear-gradient(87deg, #6386FF -15.2%, #8CA4FF 46.1%, #A0AEFF 111.9%);
}

.action-btn {
  background: linear-gradient(143deg, #403E9D -224.48%, #9391F0 78.32%);
}

.sample-table td:first-child {
  border-left: 1px solid white;
  border-radius: 10px 0px 0px 10px;
}

.sample-table td:last-child {
  border-right: 1px solid white;
  border-radius: 0px 10px 10px 0px;
}

.remark-title:nth-child(odd) {
  background-color: #FFF2B5;
}

.remark-title:nth-child(even) {
  background-color: #B5FFFB;
}

.remark-title:nth-child(odd) .remark-desc {
  background-color: #FFF7CF;
}

.remark-title:nth-child(even) .remark-desc {
  background-color: #E9FFFC;
}

.skeleton-table {
  display: table;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.skeleton-table td:first-child {
  border-radius: 10px 0px 0px 10px !important;
}

.skeleton-table td:last-child {
  border-radius: 0px 10px 10px 0px !important;
}

.list-view {
  display: table;
  border-collapse: separate;
  border-spacing: 0 6px;
}

.list-view th:nth-child(2),
.list-view td:nth-child(2) {
  padding-left: 0 !important;
}

.list-view td:first-child {
  border-radius: 6px 0px 0px 6px;
}

.list-view td:last-child {
  border-radius: 0px 6px 6px 0px;
}

.table {
  display: table;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.table td {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.table td:first-child {
  border-left: 1px solid transparent;
  border-radius: 10px 0px 0px 10px;
}

.table td:last-child {
  border-right: 1px solid transparent;
  border-radius: 0px 10px 10px 0px;
}

.table tr:hover td {
  border-top: 1px solid #E8EEFF;
  border-bottom: 1px solid #E8EEFF;
}

.table tr:hover td:first-child {
  border-left: 1px solid #E8EEFF;
  border-radius: 10px 0px 0px 10px;
}

.table tr:hover td:last-child {
  border-right: 1px solid #E8EEFF;
  border-radius: 0px 10px 10px 0px;
}

.table .disable:hover td {
  border-top: 1px solid white !important;
  border-bottom: 1px solid white !important;
}

.table .disable:hover td:first-child {
  border-left: 1px solid white !important;
  border-radius: 10px 0px 0px 10px !important;
}

.table .disable:hover td:last-child {
  border-right: 1px solid white !important;
  border-radius: 0px 10px 10px 0px !important;
}

.table .col-active td {
  border-top: 1px solid #E8EEFF;
  border-bottom: 1px solid #E8EEFF;
}

.table .col-active td:first-child {
  border-left: 1px solid #E8EEFF;
  border-radius: 10px 0px 0px 10px;
}

.table .col-active td:last-child {
  border-right: 1px solid #E8EEFF;
  border-radius: 0px 10px 10px 0px;
}

/* assign table */
.assign-table {
  display: table;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.assign-table td {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.assign-table td:first-child {
  border-left: 1px solid transparent;
  border-radius: 10px 0px 0px 10px;
}

.assign-table td:last-child {
  border-right: 1px solid transparent;
  border-radius: 0px 10px 10px 0px;
}

.assign-table .col-active td {
  border-top: 1px solid #E8EEFF;
  border-bottom: 1px solid #E8EEFF;
}

.assign-table .col-active td:first-child {
  border-left: 1px solid #E8EEFF;
  border-radius: 10px 0px 0px 10px;
}

.assign-table .col-active td:last-child {
  border-right: 1px solid #E8EEFF;
  border-radius: 0px 10px 10px 0px;
}

.first-tr:hover td:first-child,
.first-tr:hover td:last-child {
  border-right: 1px solid #E8EEFF !important;
  border-radius: 10px !important;
}

.active-sample td {
  border: 1px solid #E8EEFF !important;
}

.active-sample td:first-child {
  border-radius: 10px 0px 0px 10px !important;
  border-right: none !important;
}

.active-sample td:last-child {
  border-radius: 0px 10px 10px 0px !important;
  border-left: none !important;
}

.sec-tr:hover td {
  border: 1px solid #E8EEFF !important;
}

.sec-tr:hover td:first-child {
  border-radius: 10px 0px 0px 10px !important;
  border-right: none !important;
}

.sec-tr:hover td:last-child {
  border-radius: 0px 10px 10px 0px !important;
  border-left: none !important;
}

.active td {
  border: 1px solid #E8EEFF !important;
  border-radius: 10px !important;
}

.group-input input {
  border: 1px solid #d4d4d4 !important;
  border-radius: 10px !important;
  width: 38px !important;
  height: 47px !important;
  color: #858585 !important;
}

.group-input input:focus {
  outline: none !important;
}

.group-input div {
  gap: 30px !important;
  margin-top: 15px !important;
}

.otp input {
  width: 40px !important;
  height: 40px !important;
}

.otp div {
  gap: 15px !important;
}

.report {
  background: linear-gradient(95.89deg, #9fddff -64.79%, #b6ffcf 93.04%);
}

.report:focus {
  box-shadow: 0 0 0 0.15rem #58ff92;
  transition: none;
  transform: none;
}

.report::before {
  background: linear-gradient(90deg, transparent, #58ff92, transparent);
}

.partner {
  background: linear-gradient(96.17deg, #ffffff -54.71%, #f4d3ff 98.31%);
}

.partner::before {
  background: linear-gradient(90deg, transparent, #e38fff, transparent);
}

.partner:focus {
  box-shadow: 0 0 0 0.15rem #e38fff;
  transition: none;
  transform: none;
}

.invoice-menu {
  background: linear-gradient(133deg, rgba(34, 218, 140, 0.95) 29.86%, #147D51 149.8%);
}

.crm-menu {
  background: linear-gradient(142deg, #0068EC 21.8%, #00BBF9 73.22%);
}

.profile-menu {
  background: linear-gradient(310deg, #E3FAFF -53.53%, #FDFDFD 98.62%);
}

.user {
  background: linear-gradient(95.09deg, #ebe4ff -20.21%, #a8d3fa 96.72%);
}

.user::before {
  background: linear-gradient(90deg, transparent, #5eb1ff, transparent);
}

.user:focus {
  box-shadow: 0 0 0 0.15rem #5eb1ff;
  transition: none;
  transform: none;
}

.support {
  background: linear-gradient(93.88deg, #ffeeee 0.9%, #ddefbb 96.18%);
}

.support::before {
  background: linear-gradient(90deg, transparent, #cefd76, transparent);
}

.support:focus {
  box-shadow: 0 0 0 0.15rem #cefd76;
  transition: none;
  transform: none;
}

.details {
  background: linear-gradient(90.01deg, #d8173a 1.06%, #4684ff 98.42%);
}

.card {
  background: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(90.01deg, #d8173a 1.06%, #4684ff 98.42%) border-box;
}

.card-hover {
  position: relative;
  transition: 1.2s;
  overflow: hidden;
}

.card-hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: 1.2s;
  filter: blur(1px);
  opacity: 0.9;
}

.card-hover:hover::before {
  left: 100%;
}

.modal {
  background: linear-gradient(105.9deg, #f3ffe4 1.32%, #ffe7c2 103.09%);
}

.sample {
  background: linear-gradient(105.9deg, #ffffff 1.32%, #ffffff 103.09%);
}

.main-menu {
  transition: all ease 0.1s;
}

.submenu {
  transition: all ease 0.3s;
}

.caret-down {
  transition: 0.3s;
  transform: rotate(90deg);
}

.caret-right {
  transition: 0.3s;
  transform: rotate(0deg);
}

.section-caret-down {
  transition: 0.3s;
  transform: rotate(180deg);
}

.section-caret-up {
  transition: 0.3s;
  transform: rotate(0deg);
}

.sample-form .css-tj5bde-Svg {
  display: none !important;
}

.sample-form .css-kza2ih-menu {
  top: 208% !important;
  width: 285px !important;
  background: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  box-shadow: 5px 5px 32px 3px rgba(0, 0, 0, 0.07) !important;
  border-radius: 0px 0px 10px 10px !important;
}

.btn .p-button-label {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}

.status .p-button-label {
  font-weight: 400 !important;
  font-size: 0.813rem !important;
}

.test .p-button-label {
  font-weight: 400 !important;
  font-size: 0.813rem !important;
}

.payment .p-button-label {
  font-weight: 400 !important;
  font-size: 0.938rem !important;
  font-weight: 600 !important;
}

.date .p-inputtext {
  border-color: #20328F !important;
  color: #20328F !important;
  font-size: 0.813rem !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.range-date .p-inputtext {
  border-color: #20328F !important;
  color: #20328F !important;
  font-size: 0.813rem !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  background-color: #F3F8FF !important;
}

.date-filter .p-inputtext {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-color: #6B7280 !important;
  color: #6B7280 !important;
  border: none !important;
}

.filter-date .p-inputtext {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background-color: transparent !important;
}

.date input::placeholder {
  all: unset !important;
  color: #20328F !important;
  font-size: 0.813rem !important;
}

.upload {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.date .p-focus,
.date .p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.p-button-label {
  font-weight: 500 !important;
}

.primary .p-button-label {
  font-size: 1rem !important;
}

.p-button-label {
  font-size: 0.875rem !important;
}

.profile .p-progressbar-value {
  background: #20328F !important;
  border-radius: 20px !important;
}

.profile .p-progressbar-label {
  display: none !important;
}

.p-dropdown-trigger {
  width: 1.5rem !important;
}

.p-dropdown-trigger svg {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 0.7rem !important;
  height: 0.7rem !important;
}

.p-inputtext {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #858585;
  font-weight: 400 !important;
}

.p-dropdown-item {
  padding: 0.55rem 0.6rem !important;
  color: #858585 !important;
  font-weight: 400 !important;
  font-size: 0.938rem !important;
}

.p-progress-spinner-svg circle {
  stroke: #20328F !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: none !important;
}

.p-dropdown-items {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.p-dropdown-trigger {
  color: #6B7280 !important;
}

.p-dropdown-item {
  color: #686868 !important;
  font-size: 0.75rem !important;
}

.p-dropdown-item:hover {
  background: #edf4ff !important;
  box-shadow: 5px 5px 32px 3px rgba(0, 180, 50, 0.04) !important;
  border-radius: 10px !important;
}

.p-highlight {
  background: #edf4ff !important;
}

.service .p-checkbox-box {
  border: 1px solid #20328F !important;
  border-radius: 50% !important;
}

.service .p-highlight {
  border: 1px solid #20328F !important;
  background: #20328F !important;
  color: white !important;
}

.table-check {
  width: 18px !important;
  height: 18px !important;
  border-radius: 3px !important;
}

.table-check .p-checkbox-box {
  width: 18px !important;
  height: 18px !important;
  border-radius: 3px !important;
  border: 2px solid #E4E4E4 !important;
}

.tests .p-highlight,
.table-check .p-highlight {
  background: #20328F !important;
  color: white !important;
  border: 2px solid #20328F !important;
}

.tests .p-checkbox-box:hover,
.tests .p-checkbox-box:focus,
.tests .p-focus {
  box-shadow: 0 0 0 0.1rem #C7D2FE !important;
}

.tests .p-checkbox-box:hover,
.tests .p-checkbox-box:focus {
  border: 2px solid #20328F !important;
}

.disable-check .p-checkbox-disabled {
  background-color: #EEEEEE !important;
  border-radius: 3px !important;
  cursor: not-allowed !important;
}

.disable-check .p-disabled {
  border-color: #EEEEEE !important;
  border-radius: 3px !important;
  cursor: not-allowed !important;
}

.billing .p-checkbox-box {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #20328F !important;
  border-radius: 3px !important;
}

.bill .p-checkbox-box {
  width: 18px !important;
  height: 18px !important;
  margin-top: 2px !important;
  border: 2px solid #20328F !important;
  border-radius: 0px !important;
}

.bill .p-highlight,
.billing .p-highlight {
  background: #20328F !important;
  color: white !important;
  border: 2px solid #20328F !important;
}

.bill .p-checkbox-box,
.bill .p-checkbox-box:hover,
.bill .p-checkbox-box:focus,
.bill .p-focus,
.billing .p-checkbox-box,
.billing .p-checkbox-box:hover,
.billing .p-checkbox-box:focus,
.billing .p-focus {
  box-shadow: none !important;
}

.bill .p-checkbox-box:hover,
.bill .p-checkbox-box:focus,
.billing .p-checkbox-box:hover,
.billing .p-checkbox-box:focus {
  border: 2px solid #20328F !important;
}

.bill .p-focus,
.billing .p-focus {
  border: 2px solid #20328F !important;
  box-shadow: 0 0 0 0.1rem #b9ccf7 !important;
}

.bill .p-checkbox-box:hover,
.billing .p-checkbox-box:hover {
  box-shadow: 0 0 0 0.1rem #b9ccf7 !important;
}

.filter-check .p-checkbox-box {
  width: 16px !important;
  height: 16px !important;
}

.language .p-dropdown-label {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  color: #20328F !important;
}

.language .p-dropdown-label {
  padding: 0.3rem 0.5rem !important;
}

.emp-language .p-dropdown-label {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  color: #7c7c7c !important;
}

.emp-role .p-dropdown-label {
  display: inline-block !important;
  width: 70px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.emp-language .p-dropdown-label {
  padding: 0.3rem 0.5rem !important;
}

.country-code .p-dropdown-trigger,
.country-code .p-dropdown-label {
  color: #94a3b8 !important;
}

.country-code .p-focus {
  box-shadow: none !important;
}

.assign-user textarea {
  width: 100% !important;
  color: #94A3B8 !important;
  font-size: 0.875rem !important;
  box-shadow: none !important;
  border: none !important;
  resize: none !important;
}

.assign-user .p-mention-panel {
  left: 0 !important;
}

.p-tooltip>* {
  background-color: white !important;
  color: white !important;
}

.notification svg {
  width: 36px !important;
  height: 36px !important;
}

.swiper-pagination {
  display: contents !important;
  width: 100px !important;
}

.swipe .swiper-pagination-bullet {
  left: 40% !important;
}

.advertise .swiper-pagination-bullet {
  left: 45% !important;
}

.advertise .swiper-pagination-bullet-active {
  width: 25px !important;
  border-radius: 10px !important;
}

.swiper-pagination-bullet-active {
  background-color: #20328F !important;
}

.country .p-inputtext {
  font-size: 0.813rem !important;
  padding: 8px 10px !important;
  color: #20328F !important;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.gst-menu .css-1fdsijx-ValueContainer {
  padding: 0px 0px 0px 8px !important;
}

.gst-menu .css-16deb07-singleValue {
  margin: 0 !important;
}

.gst-menu .css-cdb9fm-indicatorContainer,
.gst-menu .css-duutti-indicatorContainer {
  padding: 0px 4px 0px 4px !important;
}

.sample .css-4nmlvq-placeholder,
.sample .css-1nphsna-Input,
.sample .css-by9nws-singleValue,
.sample .css-379c2c-Input,
.country-code .css-4nmlvq-placeholder,
.country-code .css-1nphsna-Input,
.country-code .css-by9nws-singleValue,
.country-code .css-379c2c-Input {
  font-size: 0.938rem !important;
  color: #94a3b8 !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  white-space: nowrap !important;
}

.sample .css-1dqabti-indicatorContainer,
.sample .css-dt8yex-indicatorContainer,
.sample .css-dt8yex-indicatorContainer:focus,
.sample .css-1dqabti-indicatorContainer:focus,
.country-code .css-1u9des2-indicatorSeparator,
.country-code .css-1u9des2-indicatorSeparator:focus {
  display: none !important;
}

.country-code .css-1dqabti-indicatorContainer,
.country-code .css-dt8yex-indicatorContainer,
.country-code .css-1dqabti-indicatorContainer:focus,
.country-code .css-1dqabti-indicatorContainer:hover,
.country-code .css-dt8yex-indicatorContainer:hover {
  color: #94a3b8 !important;
}

.country-code .css-18n3svc-control,
.country-code .css-1lstzdk-control,
.country-code .css-1lstzdk-control:hover,
.country-code .css-18n3svc-control:hover,
.country-code .css-18n3svc-control:focus {
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
}

.country-code .css-1nmdiq5-menu {
  width: auto !important;
}

.country-code .css-t3ipsp-control,
.country-code .css-t3ipsp-control:hover,
.country-code .css-t3ipsp-control:focus {
  border-color: #cccccc !important;
  box-shadow: none !important;
}

.country-code .css-1n6sfyn-MenuList div,
.country-code .css-166bipr-Input {
  font-size: 0.875rem !important;
  color: #94a3b8 !important;
}

.country-code .css-1dimb5e-singleValue {
  font-size: 0.938rem !important;
  color: #94a3b8 !important;
}

.sample .css-1nmdiq5-menu,
.search .css-1nmdiq5-menu {
  top: 75px !important;
  background: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  box-shadow: 5px 5px 32px 3px rgba(0, 0, 0, 0.07) !important;
  border-radius: 0px 0px 10px 10px !important;
  width: 100% !important;
}

.search .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.sample-status .css-by9nws-singleValue,
.sample-status .css-1dqabti-indicatorContainer,
.sample-status .css-dt8yex-indicatorContainer,
.sample-status .css-1nphsna-Input,
.sample-status .css-379c2c-Input,
.sample-status .css-1dqabti-indicatorContainer:hover,
.sample-status .css-1dqabti-indicatorContainer:focus,
.sample-status .css-dt8yex-indicatorContainer:hover {
  color: #686868 !important;
}

.search-sample .css-1n6sfyn-MenuList {
  top: -10px !important;
  background: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  box-shadow: 5px 5px 32px 3px rgba(0, 0, 0, 0.07) !important;
  border-radius: 0px 0px 10px 10px !important;
  width: 100% !important;
}

.search .css-1dqabti-indicatorContainer,
.search .css-dt8yex-indicatorContainer,
.search .css-dt8yex-indicatorContainer:focus,
.search .css-1dqabti-indicatorContainer:focus {
  display: none !important;
}

.search .css-4nmlvq-placeholder,
.search .css-1nphsna-Input,
.search .css-by9nws-singleValue,
.search .css-379c2c-Input {
  font-size: 0.875rem !important;
  color: #63666c !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  white-space: nowrap !important;
}

.search .css-1nphsna-Input {
  color: #20328F !important;
  font-weight: 500 !important;
}

.search .css-1nmdiq5-menu {
  top: 98px !important;
}

.search .css-by9nws-singleValue p {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.filter-select .css-1nmdiq5-menu,
.filter-select .css-1n6sfyn-MenuList {
  z-index: 100 !important;
  background-color: white !important;
}

.filter-select .css-1n6sfyn-MenuList div:hover,
.filter-select .css-1n6sfyn-MenuList div:focus {
  background-color: #edf4ff !important;
  border: 0.5px solid #85aeff !important;
}

.pdf-menu {
  position: fixed !important;
  top: 60px !important;
  display: block !important;
}

.sample-menu .p-submenu-header {
  display: none !important;
}

.sample-menu .p-menuitem-text {
  font-size: 0.875rem !important;
}

.sample-menu .p-menuitem-link {
  padding: 0.5rem 1.25rem !important;
}

.field-menu .p-menuitem-link {
  padding: 0.5rem 0.8rem !important;
}

.field-menu .p-menuitem:not(:last-child) {
  border-bottom: 1px solid #cbd5e133 !important;
}

.field-menu .p-menuitem.p-menuitem-active>.p-menuitem-link {
  background: #f4f4f4 !important;
  border-radius: 4px !important;
}

.field-menu.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
  color: #20328f !important;
}

.sample-menu .p-menuitem-link:focus {
  box-shadow: none !important;
}

.create-menu .p-menuitem-link:not(.p-disabled):hover {
  background: transparent !important;
}

.create-menu .p-menuitem-link {
  padding: 0rem !important;
}

.create-menu ul {
  margin: 0.5rem !important;
}

.create-menu .p-menuitem:not(:last-child) {
  border: none !important;
}

.create-menu .p-menuitem-text {
  width: 100% !important;
}

.create-menu {
  border-radius: 10px !important;
  border: 1px solid #D0D0D0 !important;
}

.setting-radio-btn .p-radiobutton-box.p-highlight {
  border-color: #ffa02f !important;
  background: #ffa02f !important;
}

.setting-radio-btn .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #ffa02f !important;
}

.setting-radio-btn .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.setting-radio-btn .p-radiobutton-box .p-radiobutton-icon {
  width: 10px !important;
  height: 10px !important;
}

.setting-radio-btn,
.setting-radio-btn .p-radiobutton-box {
  width: 18px !important;
  height: 18px !important;
}

.lab-radio-btn .p-radiobutton-box.p-highlight {
  border-color: #20328f !important;
  background: #20328f !important;
}

.lab-radio-btn .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #20328f !important;
}

.lab-radio-btn .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.lab-radio-btn .p-radiobutton-box .p-radiobutton-icon {
  width: 8px !important;
  height: 8px !important;
}

.lab-radio-btn,
.lab-radio-btn .p-radiobutton-box {
  width: 16px !important;
  height: 16px !important;
}

.camp-radio-btn .p-radiobutton-box .p-radiobutton-icon {
  width: 9px !important;
  height: 9px !important;
}

.camp-radio-btn,
.camp-radio-btn .p-radiobutton-box {
  width: 19px !important;
  height: 19px !important;
}

.pagination .p-paginator-pages .p-paginator-page,
.pagination .p-paginator-first,
.pagination .p-paginator-prev,
.pagination .p-paginator-next,
.pagination .p-paginator-last,
.pagination .p-dropdown {
  min-width: 1.5rem !important;
  height: 1.5rem !important;
}

.pagination .p-link,
.pagination .p-inputtext {
  font-size: 0.813rem !important;
}

.pagination {
  padding: 0 !important;
  background: transparent !important;
}

.pagination .p-dropdown {
  height: 2rem !important;
}

.Toastify__toast-container {
  width: 400px !important;
}

.Toastify__toast--error {
  background-color: #d32f2f !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  width: 100% !important;
  color: #fff !important;
}

.Toastify__toast--success {
  background-color: #58a35c !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  width: 100% !important;
  color: #fff !important;
}

.file-progress .p-progressbar-value .p-progressbar-label {
  font-size: 0.7rem !important;
}

.file-progress .p-progressbar-value {
  background-color: #20328F !important;
}

.emp-type .css-kza2ih-menu {
  top: 40% !important;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
}

.circle-1 {
  background-color: #ffa02f;
}

.circle-1::before {
  background-color: #ffa02f;
  animation-delay: 200ms;
}

.circle-2 {
  background-color: #008e5a;
}

.circle-2::before {
  background-color: #008e5a;
  animation-delay: 400ms;
}

.circle-3 {
  background-color: #7840a7;
}

.circle-3::before {
  background-color: #7840a7;
  animation-delay: 600ms;
}

.circle-4 {
  background-color: #327dc1;
}

.circle-4::before {
  background-color: #327dc1;
  animation-delay: 800ms;
}

.circle-5 {
  background-color: #435eab;
}

.circle-5::before {
  background-color: #435eab;
  animation-delay: 1000ms;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2.5);
  }

  78%,
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 450px) {
  .Toastify__toast-container {
    width: auto !important;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .status .p-button-label {
    font-weight: 400 !important;
    font-size: 0.75rem !important;
  }
}

@media only screen and (max-width: 1920px) {
  .sample .css-1nmdiq5-menu {
    width: 285px !important;
  }
}

@media only screen and (max-width: 900px) {
  .otp-input div {
    gap: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .otp-input div {
    gap: 30px !important;
  }
}

@media only screen and (max-width: 455px) {
  .group div {
    gap: 15px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .p-button-label {
    font-size: 0.813rem !important;
  }

  .advertise .swiper-pagination-bullet {
    left: 48% !important;
  }
}

@media only screen and (max-width: 1135px) {
  .sample-form .css-kza2ih-menu {
    top: 185% !important;
  }
}

@media only screen and (max-width: 1280px) {

  *,
  html,
  body {
    font-size: 0.938rem;
  }

  .primary .p-button-label {
    font-size: 0.875rem !important;
  }

  .search .css-1nmdiq5-menu {
    top: 95px !important;
  }

  .sample .css-1nmdiq5-menu {
    width: 100% !important;
  }

  .sample-form .css-kza2ih-menu {
    width: 100% !important;
  }
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 20px;
  line-height: 18px;
  padding: 0 18px 0 21px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  /* background: #fff; */
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 5px;
  position: absolute;
  width: 6px;
  top: 6px;
  border: 1px solid;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover::after {
  /* border-left-color: crimson;  */
  border-left: 7px solid;
}

.left-menu {
  width: 200px;
  transition: transform 0.15s ease-in-out;
  transform: translateX(-200px);
}

.left-menu-open {
  transform: translateX(0);
}

.left-menu-close {
  transition: transform 0.15s ease-in-out;
  transform: translateX(-200px);
}

.table-transition {
  width: 100%;
  transform: translateX(0%);
  transition: transform 0.6s ease-in;
  transition: width 0.6s ease-in;
}

.table-hide {
  width: 250px;
  transform: translateX(0%);
  transition: transform 0.6s ease-in;
  transition: width 0.6s ease-in;
}

.lab-table-hide {
  width: 270px;
  transform: translateX(0%);
  transition: transform 0.6s ease-in;
  transition: width 0.6s ease-in;
}

.profile-transition {
  width: 250px;
  transform: translateX(0%);
  transition: transform 0.6s ease-in;
  transition: width 0.6s ease-in;
}

.lab-profile-transition {
  width: 270px;
  transform: translateX(0%);
  transition: transform 0.6s ease-in;
  transition: width 0.6s ease-in;
}

.profile-hide {
  width: 100%;
  transform: translateX(0%);
  transition: transform 0.6s ease-in;
  transition: width 0.6s ease-in;
}

.task-hide {
  width: 250px;
  transform: translateX(0%);
  transition: transform 0.6s ease-in;
  transition: width 0.6s ease-in;
}

.fade-left {
  animation: fadeLeft 0.5s ease;
}

@keyframes fadeLeft {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.rcw-conversation-container {
  box-shadow: none !important;
  min-width: 300px !important;
}

.rcw-full-screen {
  height: calc(100% - 147px) !important;
  width: 300px !important;
}

.rcw-widget-container {
  right: auto !important;
}

.rcw-launcher {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 50px !important;
  height: 50px !important;
  background-color: #20328f !important;
}

.rcw-sender {
  display: flex !important;
  align-items: center !important;
  gap: 12px !important;
}

.rcw-conversation-container .rcw-header {
  background-color: #eef3fa !important;
  color: #20328f !important;
  padding: 16px !important;
  text-align: left !important;
}

.rcw-conversation-container .rcw-title {
  padding: 0 !important;
}

.rcw-client .rcw-message-text {
  background-color: #eef3fa !important;
}

.rcw-close-launcher {
  width: 18px !important;
}

.rcw-open-launcher {
  width: 30px !important;
}

.rcw-picker-btn {
  display: none !important;
}

.rcw-new-message {
  width: calc(100% - 40px) !important;
}

@media print {
  #template-header {
    display: none;
  }

  #pdf {
    display: block !important;
    padding-top: 0 !important;
  }
}

.ql-editor {
  padding: 0px !important;
  overflow: hidden !important;
}

.quill {
  height: 100% !important;
}

.body-section .quill {
  min-height: 60px !important;
}

.body-section .ql-container {
  min-height: 60px !important;
}

.body-section .ql-editor {
  min-height: 60px !important;
}

.ql-tooltip {
  z-index: 30 !important;
}

.action-menu .szh-menu {
  top: 19px !important;
}

.szh-menu__item {
  padding: 0px !important;
}

.szh-menu {
  min-width: 7.5rem !important;
}

.header-menu .szh-menu {
  min-width: 210px !important;
  top: 28px !important;
  padding: 7px 10px !important;
  border-radius: 10px !important;
  border: 1px solid #D0D0D0 !important;
  background: #FFF !important;
  box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12) !important;
}

.header-menu .szh-menu__item {
  border-radius: 4px !important;
}

.toggle-menu .szh-menu {
  border-radius: 10px !important;
  box-shadow: none !important;
  top: 8px !important;
  padding: 0 !important;
}

.toggle-menu .szh-menu__item {
  cursor: default !important;
}

.toggle-menu .szh-menu__item:hover {
  background: none !important;
}

.doc-option .szh-menu {
  border-radius: 10px !important;
  top: 22px !important;
  width: fit-content !important;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08) !important;
}

.item-option .szh-menu {
  border-radius: 8px !important;
  /* top: 25px !important; */
  width: 452px !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08) !important;
  background: white !important;
  padding: 0 !important;
  z-index: 30 !important;
}

.item-option .szh-menu .szh-menu__header {
  padding: 0 !important;
}

.toggle-submit-btn .szh-menu {
  border-radius: 10px !important;
  box-shadow: none !important;
  top: 25px !important;
  padding: 0 !important;
}

.toggle-submit-btn .szh-menu__item:hover {
  background: none !important;
}

.result-menu .szh-menu {
  border-radius: 10px !important;
  box-shadow: none !important;
  padding: 0 !important;
  top: -58px !important;
}

.result-menu .szh-menu__item:hover {
  background: none !important;
}

/* tooltip-menu */
.tooltip-menu .szh-menu__arrow {
  background: #1f2937 !important;
  border-left-color: rgba(19, 63, 151, 0.1) !important;
  border-top-color: rgba(19, 63, 151, 0.1) !important;
}

.tooltip-menu .szh-menu {
  border-radius: 9999px !important;
  padding: 6px 0 !important;
  background: #1f2937 !important;
  border: 1px solid rgba(19, 63, 151, 0.1) !important;
  width: 190px !important;
}

.tooltip-menu .szh-menu__header {
  color: white !important;
  font-size: 0.75rem !important;
  text-transform: none !important;
  padding: 0px 15px !important;
  text-align: center !important;
}

/* review checkbox */
.custom-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.custom-checkbox .background {
  fill: #ccc;
  transition: ease all 0.6s;
}

.custom-checkbox .stroke {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-dashoffset: 100;
  stroke-dasharray: 100;
  transition: ease all 0.6s;
}

.custom-checkbox .check {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke-dashoffset: 22;
  stroke-dasharray: 22;
  transition: ease all 0.6s;
}

.custom-checkbox input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  appearance: none;
  -webkit-appearance: none;
}

.custom-checkbox input[type="checkbox"]:checked+svg .background {
  fill: #4AA225;
}

.review-checkbox:hover svg .check {
  stroke-dashoffset: 0;
}

.custom-checkbox input[type="checkbox"]:checked+svg .stroke {
  stroke-dashoffset: 0;
}

.custom-checkbox input[type="checkbox"]:checked+svg .check {
  stroke-dashoffset: 0;
}

/* circular progress-bar */

.CircularProgressbar text {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
}

.fadeInUp {
  animation: fadeInUp 0.4s ease backwards;
}

.fadeInDown {
  animation: fadeInDown 0.4s ease backwards;
}

.editor-section a {
  color: revert;
}

.editor-section ul,
.view-editor-section ul {
  padding-left: 2rem !important;
  list-style: disc !important;
}

.editor-section ol,
.view-editor-section ol {
  padding-left: 2rem !important;
  list-style: decimal !important;
}

.editor-section a:hover {
  text-decoration: underline;
}

.editor-section .MuiStack-root {
  flex-wrap: wrap;
  overflow-x: hidden;
  gap: 10px;
  padding: 0px 1px;
}

.editor-section .MuiBox-root {
  margin-left: 0px;
}

.editor-section .remirror-table-controller,
.view-editor-section .remirror-table-controller {
  z-index: auto !important;
}

.result-notes .remirror-editor {
  min-height: calc(100vh - 395px) !important;
  height: calc(100vh - 395px) !important;
  overflow-y: scroll;
}

.view-editor-section .MuiStack-root {
  display: none !important;
}

.view-editor-section .ProseMirror {
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 0 !important;
}

@keyframes fadeInUp {
  0% {
    transform: translate(0px, 80px);
    opacity: 0;
  }

  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 1;
    transform: translateY(80px);
  }
}

/* payment loader */
.payment-loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.payment-loader:before,
.payment-loader:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}

.payment-loader:before {
  animation: ball1 1s infinite;
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}

.payment-loader:after {
  animation: ball2 1s infinite;
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }

  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }

  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}